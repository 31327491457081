html, body{
  overflow-x: hidden;
}

body{
  background: #F2F7F4;
  font-family: "Poppins", sans-serif;
  margin: 0;
}

.primary-text {
  color: #010a03;
  font-size: 22px;
  line-height: 30px;
}

*{
  box-sizing: border-box;
}

.primary-text-white {
  color: #f2f7f3;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.25px;
}

.secondary-text {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.25px;
}

@media (max-width: 640px) {
  .secondary-text {
    font-size: 14px;
    line-height: 21px;
  }

  .primary-text-white {
    font-size: 14px;
    line-height: 21px;
  }

  .primary-text {
    font-size: 14px;
    line-height: 21px;
  }
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.25px;
}

.button-one {
  background: #19a530;
  box-shadow: 6px 22px 14px 0px #40d55814;
}

.button-two {
  box-shadow: 0px 0px 1.44px 0px #68d47a59;
  box-shadow: 0px 0px 2.87px 0px #68d47a59;
  box-shadow: 0px 0px 10.05px 0px #68d47a59;
  box-shadow: 0px 0px 20.11px 0px #68d47a5c;
  box-shadow: 0px 0px 34.47px 0px #68d47a75;
  box-shadow: 0px 0px 60.33px 0px #68d47a59;
}


.button-three {
  background: #1aae33;
}

.hero-text {
  line-height: 60px;
}

@media (max-width: 640px) {
  .hero-text {
    line-height: 40px;
  }
}

.hero-section form button {
  background-color: #1dbf38;
  box-shadow: 1px 2px 8px 0px #1dbf38;
}

.learn-section {
  background-image: url("./assets/images/learn.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.saving-section {
  background: #19a530;
  box-shadow: 31px 71px 77px 0px #010a030a;
}

.user-section {
  background: linear-gradient(90deg, rgba(242, 247, 244, 0) 43%, #f2f7f4 80%);
}

@media (max-width: 640px) {
  .user-section {
    background: linear-gradient(90deg, rgba(242, 247, 244, 0) 63%, #f2f7f4 93%);
  }
}

.access-section {
  background: #0098ed;
}

footer {
  background: #1aae33;
}

.mob-btn {
  background: #19a530;
}
